import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ContactForm = () => {
    const { t ,i18n } = useTranslation();
    const isRTL =  i18n.language === "ar";
    return (
        <Box
            sx={{
                p: 4,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
                margin: "100px 0px"
            }}
        >
            <Typography variant='h4' fontWeight='bold' textAlign='center' gutterBottom>
                {t('contact_form.client_title')}
            </Typography>

            <Box
                sx={{
                    backgroundColor: '#FFF5EB',
                    borderRadius: 2,
                    p: 4,
                    maxWidth: 1500,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    direction:  isRTL ? "rtl" : "ltr",
                }}
            >
                <Grid container spacing={4} alignItems='center'>
                    <Grid item xs={12} md={5}>
                        <Box
                            component='img'
                            src='/assest/banner.jpg'
                            alt='Dubai Skyline'
                            sx={{ width: '100%', borderRadius: 2 }}
                        />
                    </Grid>

                    {/* Text Section */}
                    <Grid item xs={12} md={7}>
                        <Typography variant='h5' fontWeight='bold'>
                            {t('contact_form.experience_title')}
                        </Typography>
                        <Typography variant='body1' mt={2} color='text.secondary'>
                            {t('contact_form.experience_text_1')}
                        </Typography>
                        <Typography variant='body1' mt={2} color='text.secondary'>
                            {t('contact_form.experience_text_2')}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default ContactForm;
