import React from "react";
import { Box, Typography, Container } from "@mui/material";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === "ar";  // Check if language is Arabic (RTL)

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#F8F3EB",
          color: "black",
          textAlign: "center",
          padding: "2rem 1rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          direction: isRTL ? "rtl" : "ltr",  // Set direction based on language
        }}
      >
        <Box mb={2}>
          <img src="/assest/hayat.png" alt="Hayat Almina Logo" width={120} />
        </Box>

        <Typography variant="h6" fontWeight="bold" mb={2} maxWidth="80%">
          {t("footer.slogan")}
        </Typography>

        <Box display="flex" flexDirection="column" gap={1} alignItems="center">
          <Typography variant="body2">
            {t("footer.international_communications")}
          </Typography>
          <Typography variant="body2">
            {t("footer.business_management")}
          </Typography>
          <Typography variant="body2">{t("footer.ceo")}</Typography>
          <Typography variant="body2">{t("footer.support")}</Typography>
        </Box>
      </Box>

      <Box
        sx={{
          backgroundColor: "#A38668",
          width: "100%",
          height: "100px",
          alignItems: "center",
          textAlign: "center",
          padding: "1rem 0",
          direction: isRTL ? "rtl" : "ltr",  // Set direction for the bottom box
        }}
      >
        <Typography variant="body2" sx={{ fontSize: "14px" }}>
          {t("footer.all_rights_reserved")}
        </Typography>
      </Box>
    </>
  );
};

export default Footer;
