import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { styled } from "@mui/material/styles";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useTranslation } from "react-i18next";

const SlideContainer = styled(Box)(({
  position: "relative",
  width: "100%",
  height: "100vh",
  overflow: "hidden",
}));

const OverlayBox = styled(Box)(({ theme, dir }) => ({
  position: "absolute",
  top: "50%",
  [dir === "rtl" ? "left" : "right"]: "5%",
  transform: "translateY(-50%)",
  backgroundColor: "rgba(0, 0, 0, 0.7)",
  padding: "40px",
  borderRadius: "16px",
  color: "#fff",
  maxWidth: "450px",
  height: "450px",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.3)",
  [theme.breakpoints.down("md")]: {
    width: "90%",
    height: "400px",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "20px",
    height: "350px",
  },
}));

const Divider = styled("hr")({
  width: "80%",
  border: "0.5px solid rgba(255, 255, 255, 0.4)",
  margin: "10px 0",
});

const IconButtonStyled = styled(IconButton)({
  color: "#fff",
  backgroundColor: "rgba(255, 255, 255, 0.2)",
  margin: "10px",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.4)",
  },
});

const ResponsiveTypography = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  fontWeight: 700,
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "1.2rem",
  },
}));

export default function HeroSlider() {
  const { i18n, t } = useTranslation();
  const dir = i18n.language === "ar" ? "rtl" : "ltr";

  return (
    <SlideContainer dir={dir}>
      <Swiper
        navigation
        pagination={{ clickable: true }}
        autoplay={{ delay: 5000 }}
        loop
        modules={[Navigation, Pagination, Autoplay]}
        style={{ width: "100%", height: "100%" }}
      >
        <SwiperSlide>
          <Box
            component="img"
            src="/assest/view2.jpg"
            alt="Dubai Skyline"
            sx={{ width: "100%", height: "100vh", objectFit: "cover" }}
          />
          <OverlayBox dir={dir}>
            <ResponsiveTypography>
              {t('message')}
            </ResponsiveTypography>
            <Divider />
            <Typography variant="body1" textAlign={dir === "rtl" ? "right" : "left"}>
              info@hayatalmina.com
            </Typography>
            <Divider />
            <Typography variant="body1" fontWeight={600} sx={{ textAlign: "left" }}>
              {t('phone')}
            </Typography>
            <Divider />
            <Box display="flex" flexDirection={dir === "rtl" ? "row-reverse" : "row"}>
              <IconButtonStyled href="https://www.linkedin.com/hayatlmina" target="_blank">
                <LinkedInIcon />
              </IconButtonStyled>
              <IconButtonStyled href="https://wa.me/+971523043369" target="_blank">
                <WhatsAppIcon />
              </IconButtonStyled>
            </Box>
          </OverlayBox>
        </SwiperSlide>
      </Swiper>
    </SlideContainer>
  );
}
