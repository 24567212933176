import React, { useState } from "react";
import { AppBar, Toolbar, Typography, Box, Menu, MenuItem, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { styled } from "@mui/material/styles";
import { ScrollTo } from 'react-scroll-to';
import TranslateButton from '../Translate/TranslateBtn'
const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  position: "relative",
  width: "100%",
  height: "80px",
}));

const NavLinks = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  gap: theme.spacing(3),
  color: "#1E1E1E",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
  "& > *": {
    fontSize: theme.typography.body1.fontSize,
    cursor: "pointer",
    transition: "color 0.3s ease",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

const MobileMenu = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const LogoContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
}));

const Logo = styled("img")({
  width: 120,
  height: 120,
  objectFit: "contain",
});

const MenuTypography = styled(Typography)(({ theme }) => ({
  fontSize: "1.1rem", // Adjust font size
  padding: theme.spacing(1, 2),
  cursor: "pointer",
  transition: "color 0.3s ease",
  "&:hover": {
    color: theme.palette.primary.main,
  }, whiteSpace: "nowrap",
}));

export default function Navbar() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="sticky"
      color="default"
      elevation={0}
      sx={{
        backgroundColor: "#FFFFFF",
        width: "100%",
        height: "80px",
      }}
    >
      <StyledToolbar>
        <NavLinks>
          <ScrollTo>
            {({ scroll }) => (
              <MenuTypography onClick={() => scroll({ x: 0, y: document.getElementById('contact').offsetTop, smooth: true })}>
                Contact Us
              </MenuTypography>
            )}
          </ScrollTo>
          <ScrollTo>
            {({ scroll }) => (
              <MenuTypography onClick={() => scroll({ x: 0, y: document.getElementById('business').offsetTop, smooth: true })}>
                Business
              </MenuTypography>
            )}
          </ScrollTo>
          <ScrollTo>
            {({ scroll }) => (
              <MenuTypography onClick={() => scroll({ x: 0, y: document.getElementById('about').offsetTop, smooth: true })}>
                About Us
              </MenuTypography>
            )}
          </ScrollTo>
        </NavLinks>

        <LogoContainer>
          <Logo src="/assest/hayat.png" alt="HAYAT ALMINA" />
        </LogoContainer>

        <Box display="flex" alignItems="center" gap={1}>
         <TranslateButton/>
        </Box>

        <MobileMenu>
          <IconButton edge="end" color="inherit" onClick={handleMenu}>
            <MenuIcon />
          </IconButton>
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            <ScrollTo>
              {({ scroll }) => (
                <MenuItem onClick={() => scroll({ x: 0, y: document.getElementById('contact').offsetTop, smooth: true })}>
                  <Typography variant="body1" sx={{ fontSize: "1.2rem" }}>Contact Us</Typography>
                </MenuItem>
              )}
            </ScrollTo>
            <ScrollTo>
              {({ scroll }) => (
                <MenuItem onClick={() => scroll({ x: 0, y: document.getElementById('business').offsetTop, smooth: true })}>
                  <Typography variant="body1" sx={{ fontSize: "1.2rem" }}>Business</Typography>
                </MenuItem>
              )}
            </ScrollTo>
            <ScrollTo>
              {({ scroll }) => (
                <MenuItem onClick={() => scroll({ x: 0, y: document.getElementById('about').offsetTop, smooth: true })}>
                  <Typography variant="body1" sx={{ fontSize: "1.2rem" }}>About Us</Typography>
                </MenuItem>
              )}
            </ScrollTo>
          </Menu>
        </MobileMenu>
      </StyledToolbar>
    </AppBar>
  );
}
