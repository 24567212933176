import React from 'react'
import Slider from './Components/Slider'
import Nav from './Components/Nav'
import AboutUs from './Components/about/About'
import Footer from './Components/Footer/Footer'
import OurServices from './Components/Servisess/Servicess'
import ContactForm from './Components/Form'
import Content from './Components/about/Content'


function Home() {
  return (
    <div>
      <Nav />

      <section id="contact">
        <Slider />
      </section>
      <section id="about">
        <AboutUs />
      </section>

      <Content />


      <section id="business">
        <OurServices />
      </section>

      <ContactForm />
      <Footer />
    </div>
  )
}

export default Home
