import React from 'react';
import { Container, Grid, Card, CardContent, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const OurServices = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'ar';

  return (
    <Container maxWidth="lg" sx={{ py: 5 }}>
      <Typography variant="h4" fontWeight="bold" gutterBottom sx={{ py: 5 }} textAlign={isRTL ? 'right' : 'left'  }>
        {t('our_services')}
      </Typography>
      <Typography variant="body1" color="textSecondary" gutterBottom sx={{ textAlign:  'canter' }}>
        {t('services_description')}
      </Typography>
      <Grid container spacing={3} sx={{ mt: 3, justifyContent: 'center' }}>
        <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', justifyContent: 'center'  }}>
          <Card sx={{ width: 320, height: 370, borderRadius: 1, p: 3, border: '1px solid #A78869', backgroundColor: '#FFF9EE99', textAlign: isRTL ? 'right' : 'left' }}>
            <Box component="img" src="/assest/www.png" sx={{ width: 120, height: 120, mb: 2 }} />
            <Typography variant="h6" fontWeight="bold">{t('web_development')}</Typography>
            <Typography variant="body2" color="textSecondary">{t('web_development_desc')}</Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Card sx={{ width: 320, height: 370, borderRadius: 1, p: 3, border: '1px solid #A78869', backgroundColor: '#FFF9EE99', textAlign: isRTL ? 'right' : 'left' }}>
            <Box component="img" src="/assest/solo.png" sx={{ width: 120, height: 120, mb: 2 }} />
            <Typography variant="h6" fontWeight="bold">{t('custom_software')}</Typography>
            <Typography variant="body2" color="textSecondary">{t('custom_software_desc')}</Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Card sx={{ width: 320, height: 370, borderRadius: 1, p: 3, border: '1px solid #A78869', backgroundColor: '#FFF9EE99', textAlign: isRTL ? 'right' : 'left' }}>
            <Box component="img" src="/assest/search.png" sx={{ width: 120, height: 120, mb: 2 }} />
            <Typography variant="h6" fontWeight="bold">{t('market_research')}</Typography>
            <Typography variant="body2" color="textSecondary">{t('market_research_desc')}</Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Card sx={{ width: 320, height: 370, borderRadius: 1, p: 3, border: '1px solid #A78869', backgroundColor: '#FFF9EE99', textAlign: isRTL ? 'right' : 'left' }}>
            <Box component="img" src="/assest/market.png" sx={{ width: 120, height: 120, mb: 2 }} />
            <Typography variant="h6" fontWeight="bold">{t('digital_marketing')}</Typography>
            <Typography variant="body2" color="textSecondary">{t('digital_marketing_desc')}</Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Card sx={{ width: 320, height: 370, borderRadius: 1, p: 3, border: '1px solid #A78869', backgroundColor: '#FFF9EE99', textAlign: isRTL ? 'right' : 'left' }}>
            <Box component="img" src="/assest/legestic.png" sx={{ width: 120, height: 120, mb: 2 }} />
            <Typography variant="h6" fontWeight="bold">{t('logistics_services')}</Typography>
            <Typography variant="body2" color="textSecondary">{t('logistics_services_desc')}</Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Card sx={{ width: 320, height: 370, borderRadius: 1, p: 3, border: '1px solid #A78869', backgroundColor: '#FFF9EE99', textAlign: isRTL ? 'right' : 'left' }}>
            <Box component="img" src="/assest/con.png" sx={{ width: 120, height: 120, mb: 2 }} />
            <Typography variant="h6" fontWeight="bold">{t('business_consultancy')}</Typography>
            <Typography variant="body2" color="textSecondary">{t('business_consultancy_desc')}</Typography>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default OurServices;