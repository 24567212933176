import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Container, useTheme, useMediaQuery, Box } from '@mui/material';

const AboutUs = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isRTL = i18n.language === "ar"; // Check if the language is Arabic (RTL)

  return (
    <Container sx={{ py: 4, width: "100%", direction: isRTL ? 'rtl' : 'ltr' , marginTop:"60px" }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant={isMobile ? 'h4' : 'h3'} component="h1" gutterBottom align={isRTL ? "right" : "left"}>
            {t('about_us.title')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={isMobile ? 'body1' : 'h6'} component="p" align={isRTL ? "right" : "left"}>
            {t('about_us.content')}
          </Typography>
        </Grid>
      </Grid>

      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          gap: 2,
          justifyContent: 'center',
          alignItems: 'center',
          padding: 2,
          flexWrap: 'wrap',
        }}
      >
        <Box
          sx={{
            width: isMobile ? '100%' : '30%',
            aspectRatio: '1 / 1',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: 'center',
            borderRadius: 1,
          }}
        >
          <img
            src="/assest/view.jpg"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: 1,
            }}
          />
          <img
            src="/assest/meet.jpg"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: 1,
              margin: "10px 60px",
            }}
          />
          <img
            src="/assest/me.jpg"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: 1,
            }}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default AboutUs;
