
import './App.css';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import Home from './Home';
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";

function App() {
  // const { i18n } = useTranslation();
  // useEffect(() => {
  //   if (i18n.language === 'en') {
  //     document.body.classList.add('ltr');
  //     document.body.classList.remove('rtl');
  //   } else {
  //     document.body.classList.add('rtl');
  //     document.body.classList.remove('ltr');
  //   }
  // }, [i18n.language]);
  return (
    <div className="App">
     <Routes>

   
<Route path="/" exact={true} element={<Home />} />
</Routes>
    </div>
  );
}

export default App;
