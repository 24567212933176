import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Avatar } from '@mui/material';
import 'flag-icons/css/flag-icons.css';
import './btn.css';

const TranslateButton = () => {
  const { i18n } = useTranslation();
  const [textDirection, setTextDirection] = useState('ltr');

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem("lng", language);
    setTextDirection(language === 'en' ? 'ltr' : 'rtl');
  };

  useEffect(() => {
    setTextDirection(i18n.language === 'en' ? 'ltr' : 'rtl');
  }, [i18n.language]);

  return (
    <div className='option' style={{ direction: textDirection, display: 'flex', gap: '10px' }}>
      <IconButton onClick={() => changeLanguage('en')}>
        <Avatar className="flag-icon" variant="circular">
          <span className="fi fi-gb"></span> {/* UK Flag for English */}
        </Avatar>
      </IconButton>

      <IconButton onClick={() => changeLanguage('ar')}>
        <Avatar className="flag-icon" variant="circular">
          <span className="fi fi-ae"></span> {/* UAE Flag for Arabic */}
        </Avatar>
      </IconButton>
    </div>
  );
};

export default TranslateButton;
