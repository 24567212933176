import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Button, Container, Box, useTheme, useMediaQuery } from '@mui/material';

const Content = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Determine text alignment and direction based on language
  const textAlign = i18n.language === 'ar' ? 'right' : 'left';
  const direction = i18n.language === 'ar' ? 'rtl' : 'ltr';

  return (
    <Container 
      maxWidth="xl" 
      sx={{ 
        py: 4, 
        backgroundColor: "#FF9D001A", 
        width: "100%", 
        height: "auto", 
        px: 2,
        direction: direction,  
        marginTop:"100px"
      }}
    >
      <Grid container spacing={2} direction="column" alignItems="center" sx={{ direction }}>
        <Grid item xs={12} md={10}>
          <Box
            sx={{
              width: '100%',
              height: "auto",  // Set height to auto so content flows naturally
              display: 'flex',
              flexDirection: "column",
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 2, 
            }}
          >
            <img
              src="/assest/office.png" 
              alt="myOffice"
              style={{
                width: '100%',
                height: 'auto',  // Make sure the height adjusts based on the image
                objectFit: 'contain',  // To make sure the image doesn't stretch
                borderRadius: 2, 
              }}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={10}>
          <Typography 
            variant={isMobile ? 'h4' : 'h3'} 
            component="h1" 
            gutterBottom 
            align={textAlign}  
            sx={{
              marginTop: "30px" // Add some space between the image and text
            }}
          >
            {t('case_studies.title')}
          </Typography>
          <Typography 
            variant={isMobile ? 'body1' : 'h6'} 
            component="p" 
            align={textAlign}  // Apply textAlign dynamically based on language
          >
            {t('case_studies.content')}
          </Typography>
      
          <Box 
            sx={{ 
              display: 'flex', 
              justifyContent: textAlign === 'right' ? 'flex-end' : 'flex-start',  // Adjust button alignment based on textAlign
              mt: 3 
            }}
          >
            {/* Uncomment if you want the button */}
            {/* <Button variant="contained" color="primary">
              {t('case_studies.buttonText')}
            </Button> */}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Content;
